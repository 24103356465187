<template>
  <filtro-rapido v-model="abrirFiltro"
                 ref="filtrosRapidos"
                 :campos-formulario="camposFormulario"
                 :metadados="getAcaoMetadado"
                 :ordenacao-campos="ordenacaoCampos"
                 :entidade-workspace="entidadeWorkspace"
                 :aplicar-filtro-no-carregamento="aplicarFiltroNoCarregamento"
                 @FiltroRapido__AplicaFiltros="aplicarFiltros"></filtro-rapido>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '@/produto/shared-components/filtro-rapido/FiltroRapido';
import { buscarProdutosSelecao } from '@/produto/common/resources/produto';
import { buscarClientes } from '@/produto/common/resources/planejamento/planejamento-acao-cadastro';
import { buscarHoldings } from '@/common/resources/suzano-planejamento-acao-cadastro';

export default {
  name: 'ApuracaoAcaoFiltro',
  props: {
    value: false,
    aplicarFiltroNoCarregamento: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FiltroRapido,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.apuracaoConfiguracao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      usuariosResource: this.$api.usuario(this.$resource),
      tipoBeneficioResource: this.$api.tipoBeneficio(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),

      abrirFiltro: false,
      metadadosPlanejamentoAcao: null,
      ordenacaoCampos: null,
      entidadeWorkspace: 'apuracao_acao',

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_de_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.orcamentoTipoVerbaResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'periodo',
            nomCampoId: 'ids_periodo',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: () => this.anoFiscalResource.buscarPeriodosPorTipo({ tipoPeriodo: 'PLANEJAMENTO_ORCAMENTARIO' }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'LISTA_CHECKBOX',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade.apuracao_previa'),
                  valor: 'APURACAO_PREVIA',
                },
                {
                  texto: this.$t('status_entidade.aguardando_apuracao'),
                  valor: 'AGUARDANDO_APURACAO',
                },
                {
                  texto: this.$t('status_entidade.aguardando_aprovacao'),
                  valor: 'AGUARDANDO_APROVACAO',
                },
                {
                  texto: this.$tc('status_entidade.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$t('status_entidade.cancelado'),
                  valor: 'CANCELADO',
                },
                {
                  texto: this.$tc('status_entidade.em_analise', 1),
                  valor: 'EM_ANALISE',
                },
                {
                  texto: this.$tc('status_entidade.reprovado', 1),
                  valor: 'REPROVADO',
                },
                {
                  texto: this.$t('status_entidade.vencido'),
                  valor: 'VENCIDO',
                },
              ],
            },
          },
          {
            labelCampo: 'ano',
            nomCampoId: 'ano',
            tipoCampo: 'LISTA',
            desAtributos: {
              dependentes: ['periodo_planejamento'],
            },
            async: {
              fetchFunction: (ano) => this.anoFiscalResource.listarAnosDisponiveis({ ano }),
              itemValue: 'ano',
              itemText: 'ano',
            },
            events: {
              change: (anoSelecionado) => {
                this.$refs.filtrosRapidos.ano = anoSelecionado;
              },
            },
          },
          {
            labelCampo: 'periodo_planejamento',
            nomCampoId: 'id_periodo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: 'ano',
            },
            async: {
              fetchFunction: async () => {
                const parametros = { ...this.$refs.filtrosRapidos.getValoresDependencias() };
                let anoSelecionado = 2025;

                if (parametros.ano) {
                  anoSelecionado = parametros.ano;
                  return this.anoFiscalResource.listarMesesDisponiveis({
                    ano: anoSelecionado,
                  });
                }
                const anos = await this.anoFiscalResource.listarAnosDisponiveis();

                const listaAnos = anos.body;

                const { ano } = listaAnos[listaAnos.length - 1];

                return this.anoFiscalResource.listarMesesDisponiveis({
                  ano,
                });
              },
              itemValue: 'mes',
              itemText: 'mes',
            },
          },
          {
            labelCampo: 'cod_planejamento_massivo',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'cod_acao_tatica',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'minhas_aprovacoes_pendentes',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'id_produto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  // Filtro utilizado para filtrar a combo de Produtos por UN do usuário logado
                  filtrarPorExtensao: true,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
          {
            labelCampo: 'hierarquia',
            nomCampoId: 'hierarquias',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  idNivelExtensao: 8,
                  indiceRecursao: 2,
                };
                return this.extensaoResource.listarAtivos(parametros, this.$resource);
              },
              itemValue: 'nomExtensao',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'tipo_pagamento',
            nomCampoId: 'id_tipo_pagamento',
            tipoCampo: 'LISTA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: 'Abatimento',
                  valor: '1',
                },
                {
                  texto: 'Depósito em conta',
                  valor: '2',
                },
              ],
            },
          },
          {
            labelCampo: 'tipo_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (filter) => this.orcamentoTipoVerbaResource.buscarPorVisao({ filter }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarHoldings(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'regional',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependentes: ['usuario'],
            },
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarRegionaissPorVisao({ filtro: autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'usuario_responsavel',
            nomCampoId: 'id_usuario_criacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: ['divisao'],
            },
            async: {
              fetchFunction: () => {
                const divisao = this.$refs.filtrosRapidos.filtros.divisao || [];
                const idsDivisoes = divisao.map((p) => p.id);
                return this.usuariosResource.buscarUsuarios({ idsDivisoes });
              },
              itemValue: 'id',
              itemText: 'nomeSobrenome',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'ids_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarClientes(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }

      filtrosAplicados.cod_acao = filtrosAplicados.cod_acao_tatica;
      filtrosAplicados.cod_acao_massiva = filtrosAplicados.cod_planejamento_massivo;
      filtrosAplicados.id_tipo_pagamento = filtrosAplicados.tipo_pagamento;

      delete filtrosAplicados.tipo_pagamento;
      delete filtrosAplicados.cod_planejamento_massivo;
      delete filtrosAplicados.cod_acao_tatica;

      if (this.aplicarFiltroNoCarregamento) {
        this.$emit('ApuracaoAcaoFiltro__filtroCarregado');
      }
      this.$emit('ApuracaoAcaoFiltro__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && (filtrosAplicados.data_inicio || filtrosAplicados.data_fim)) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro({ tipo: 'ACAO' })
        .then((res) => {
          this.ordenacaoCampos = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
